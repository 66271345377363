import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/",
    component: () =>
      import(/* webpackChunkName: "WebLayout" */ "@/layouts/WebLayout.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
        meta: {
          requiresAuth: false,
          title: "Home - Municipalidad de Maipú"
        }
      },
      // {
      //   path: "/test/organigrama",
      //   name: "Organigrama",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "OrganigramaMuni" */ "@/views/Municipalidad/OrganigramaMuniView.vue"
      //     ),
      //   meta: {
      //     title: "Organigrama Municipal - Municipalidad de Maipú"
      //   }
      // },
      {
        path: "/municipalidad/organigrama",
        name: "OrganigramaDos",
        component: () =>
          import(
            /* webpackChunkName: "OrganigramaMuni" */ "@/views/Municipalidad/OrganigramaView.vue"
          ),
        meta: {
          title: "Organigrama Municipal - Municipalidad de Maipú"
        }
      },
      {
        path: "/licitaciones",
        name: "Licitaciones",
        component: () =>
          import(
            /* webpackChunkName: "Licitaciones" */ "@/views/Licitaciones/LicitacionesView.vue"
          ),
        meta: {
          title: "Licitaciones - Municipalidad de Maipú"
        }
      },
      {
        path: "/licitaciones/:name",
        name: "Licitacion",
        component: () =>
          import(
            /* webpackChunkName: "Licitacion" */ "@/views/Licitaciones/LicitacionView.vue"
          ),
        meta: {
          title: "Licitaciones - Municipalidad de Maipú"
        }
      },
      {
        path: "/oficinas-y-telefonos-municipales",
        name: "Telefonos",
        component: () =>
          import(
            /* webpackChunkName: "telefonosMuni" */ "@/views/Web/TelefonosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Oficinas y Teléfonos - Municipalidad de Maipú"
        }
      },
      {
        path: "/tramites-y-pagos-online",
        name: "Tramites",
        component: () =>
          import(
            /* webpackChunkName: "tramites" */ "@/views/Web/TramitesView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Trámites y pagos online - Municipalidad de Maipú"
        }
      },
      {
        path: "/galerias",
        name: "Galerias",
        component: () =>
          import(
            /* webpackChunkName: "galerias" */ "@/views/Galeria/GaleriaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Galerías - Municipalidad de Maipú"
        }
      },
      {
        path: "/galeria/:nombre",
        name: "DetalleGaleria",
        component: () =>
          import(
            /* webpackChunkName: "detallegaleria" */ "@/views/Galeria/DetalleGaleriaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Galeria"
        }
      },
      {
        path: "/noticias",
        name: "Noticias",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Noticias/NoticiasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Noticias - Municipalidad de Maipú"
        }
      },
      {
        path: "/noticias/publicacion/:nombre",
        name: "PublicacionLegacy",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/Noticias/PublicacionLegacyView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticia"
        }
      },
      {
        path: "/noticias/publicacion/:id/:nombre",
        name: "Publicacion",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/Noticias/PublicacionView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticia"
        }
      },
      {
        path: "/contenido/noticias/preview/:nombre",
        name: "PublicacionPreview",
        component: () =>
          import(
            /* webpackChunkName: "PublicacionPreview" */ "@/views/Noticias/PublicacionPreviewView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticia Preview"
        }
      },
      {
        path: "/noticias/categoria/:nombre",
        name: "Categoria",
        component: () =>
          import(
            /* webpackChunkName: "Categoria" */ "@/views/Noticias/CategoriaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticias"
        }
      },
      {
        path: "/comuna/:nombre",
        name: "Comuna",
        component: () =>
          import(
            /* webpackChunkName: "Comuna" */ "@/views/Comuna/GenericoView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Comuna"
        }
      },
      // {
      //   path: "/municipalidad/:nombre",
      //   name: "Municipalidad",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "Municipalidad" */ "@/views/Municipalidad/GenericoView.vue"
      //     ),
      //   meta: {
      //     notLogged: false,
      //     requiresAuth: false,
      //     title: "Municipalidad de Maipú"
      //   }
      // },
      {
        path: "/destacado/:nombre",
        name: "Destacado",
        component: () =>
          import(
            /* webpackChunkName: "Destacado" */ "@/views/Destacado/GenericoView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Destacado"
        }
      },
      {
        path: "/:nombre",
        name: "Pagina",
        component: () =>
          import(
            /* webpackChunkName: "Pagina" */ "@/views/Pagina/GenericoView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Municipalidad de Maipú"
        }
      },
      {
        path: "/contenido/pagina/preview/:nombre",
        name: "PaginaPreview",
        component: () =>
          import(
            /* webpackChunkName: "PaginaPreview" */ "@/views/Pagina/PreviewView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Preview Página"
        }
      },
      {
        path: "/contacto/formulario",
        name: "Contacto",
        component: () =>
          import(
            /* webpackChunkName: "contacto" */ "@/views/Contacto/ContactoView.vue"
          ),
        meta: {
          title: "Formulario de Consultas - Municipalidad de Maipú"
        }
      },
      {
        path: "/concejo/municipal",
        name: "Concejo",
        component: () =>
          import(
            /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ConcejoView.vue"
          ),
        meta: {
          title: "Concejo Municipal - Municipalidad de Maipú"
        }
      },
      {
        path: "/centros/atencion",
        name: "Cam",
        component: () =>
          import(
            /* webpackChunkName: "Cam" */ "@/views/Municipalidad/CamView.vue"
          ),
        meta: {
          title: "Centros de Atención Municipal - Municipalidad de Maipú"
        }
      },
      {
        path: "/organizaciones/ley-21146",
        name: "Organizaciones",
        component: () =>
          import(
            /* webpackChunkName: "organizaciones" */ "@/views/Municipalidad/OrganizacionesView.vue"
          ),
        meta: {
          title: "Ley 21.146 - Municipalidad de Maipú"
        }
      },
      {
        path: "/municipio/decretos",
        name: "Decretos",
        component: () =>
          import(
            /* webpackChunkName: "organizaciones" */ "@/views/Municipalidad/DecretosView.vue"
          ),
        meta: {
          title: "Decretos Municipales - Municipalidad de Maipú"
        }
      },
      {
        path: "/omil/ofertas",
        name: "OfertasOmil",
        component: () =>
          import(
            /* webpackChunkName: "oferta" */ "@/views/Web/OfertasOmilView.vue"
          ),
        meta: {
          title: "Ofertas Laborales OMIL - Municipalidad de Maipú"
        }
      },
      {
        path: "/municipio/boletines",
        name: "Boletines",
        component: () =>
          import(
            /* webpackChunkName: "oferta" */ "@/views/Web/BoletinesView.vue"
          ),
        meta: {
          title: "Boletines - Municipalidad de Maipú"
        }
      },
      {
        path: "/municipio/encuestas/:id/:nombre",
        name: "Encuesta",
        component: () =>
          import(
            /* webpackChunkName: "encuesta" */ "@/views/Encuesta/EncuestaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Municipalidad de Maipú"
        }
      },
      {
        path: "/municipio/encuestas",
        name: "ListaEncuestas",
        component: () =>
          import(
            /* webpackChunkName: "ListaEncuestas" */ "@/views/Encuesta/ListaEncuestasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Encuestas - Municipalidad de Maipú"
        }
      },
      {
        path: "/category/noticia/",
        redirect: "/noticias"
      },
      { path: "/wp-content/*", redirect: "/" },
      { path: "/category/*", redirect: "/" },
      { path: "/tag/*", redirect: "/" }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
