import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";
import i18n from "./i18n";
import "@/scss/custom.scss";
import VueGtag from "vue-gtag";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Vue2OrgTree from "vue2-org-tree";
import VueTree from "@ssthouse/vue-tree-chart";

Vue.component("vue-tree", VueTree);

Vue.use(Vue2OrgTree);
Vue.use(VueSweetalert2);
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_ANALYTICS_ID },
    pageTrackerTemplate(to) {
      let title = to.name;
      if (title == "Home") {
        title = "Home - Municipalidad de Maipú";
      }
      if (title == "Telefonos") {
        title = "Oficinas y Teléfonos - Municipalidad de Maipú";
      }
      if (title == "Tramites") {
        title = "Trámites y pagos online - Municipalidad de Maipú";
      }
      if (title == "Galerias") {
        title = "Galerías - Municipalidad de Maipú";
      }
      if (title == "DetalleGaleria") {
        title = "Galeria";
      }
      if (title == "Noticias") {
        title = "Noticias - Municipalidad de Maipú";
      }
      if (title == "Publicacion") {
        title = "Municipalidad de Maipú";
      }
      if (title == "PublicacionPreview") {
        title = "Noticia Preview";
      }
      if (title == "Categoria") {
        title = "Noticias";
      }
      if (title == "Comuna") {
        title = "Comuna";
      }
      if (title == "Municipalidad") {
        title = "Municipalidad de Maipú";
      }
      if (title == "Destacado") {
        title = "Destacado";
      }
      if (title == "Pagina") {
        title = "Municipalidad de Maipú";
      }
      if (title == "PaginaPreview") {
        title = "Preview Página";
      }
      if (title == "Contacto") {
        title = "Formulario de Consultas - Municipalidad de Maipú";
      }
      if (title == "Concejo") {
        title = "Concejo Municipal - Municipalidad de Maipú";
      }
      if (title == "Cam") {
        title = "Centros de Atención Municipal - Municipalidad de Maipú";
      }
      if (title == "Organizaciones") {
        title = "Ley 21.146 - Municipalidad de Maipú";
      }
      if (title == "OfertasOmil") {
        title = "Ofertas Laborales OMIL - Municipalidad de Maipú";
      }
      if (to.params.nombre) {
        if (title == "Municipalidad de Maipú") {
          title = to.params.nombre.replaceAll("-", " ") + " - " + title;
        } else {
          title = title + " - " + to.params.nombre.replaceAll("-", " ");
        }
      }
      return {
        page_title: title,
        page_path: to.path
      };
    }
  },
  router
);

// Registra el filtro globalmente
Vue.filter('truncate', (text: string, length: number, clamp: string = '...') => {
  if (!text) return '';
  const node = document.createElement('div');
  node.innerHTML = text;
  const content = node.textContent || '';
  return content.length > length ? content.slice(0, length) + clamp : content;
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
